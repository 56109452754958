import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

const UpdateResumeThankYouPage: React.FC = () => {
    const location = useLocation();
    const thankYouMessage = location.state?.message || "Thank you for your submission!";
    const intl = useIntl();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-50 to-white text-gray-800">
            <div className="p-6 bg-white shadow-lg rounded-lg max-w-lg text-center">
                <h1 className="text-2xl font-bold text-green-700 mb-4"> {intl.formatMessage({ id: "thankYou.title", defaultMessage: "🎉 Thank You!" })}</h1>
                <p className="text-lg">{thankYouMessage}</p>
                <div className="mt-6">
                    <button
                        onClick={() => window.history.back()}
                        className="px-6 py-3 bg-green-600 text-white rounded-xl cursor-pointer transition duration-300">
                        {intl.formatMessage({ id: "global.back", defaultMessage: "Go Back" })}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UpdateResumeThankYouPage;
