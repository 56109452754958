import { IntlShape } from "react-intl";

export type TypeOfQuestion = 'open' | 'boolean' | 'multipleChoice' | 'singleChoice' | 'date' | 'dateTime';
export type AnswerType = string | AnswerOptionModel[] | boolean | null;

export interface ApplicantFormData {
    name: string;
    familyName: string;
    email: string;
    phone: string;
    resume: string | ArrayBuffer | null;
    fullAddress: string;
    state: string;
    postalCode: string;
    city: string;
    province: string;
    address: string;
    birthDate: string;
    studyLevel: EducationLevel | "";
    currentPosition: string;
    yearsOfExperience: string;
    currentWorkingArea: string;
    privacyConsent: boolean;
    formAnswers?: JobFormAnswerModel[];
}

export type JobFormModel = {
    formID: string;
    authorID: string;
    title: string;
    description: string;
    totalScore: number;
    questions: FormQuestionModel[];
}

export type FormQuestionModel = {
    questionID: string;
    type: TypeOfQuestion;
    questionText: string;
    points: number;
    options?: QuestionOptionModel[];
    isMandatory: boolean;
}

export type QuestionOptionModel = {
    optionID: string;
    text: string;
    isSelected: boolean;
    points: number;
}

export type JobFormAnswerModel = {
    formID: string;
    authorID: string;
    title: string;
    description: string;
    totalScore: number;
    submissionDate: string;
    answers: FormAnswerModel[];
}

export type FormAnswerModel = {
    questionID: string;
    questionText: string;
    isMandatory: boolean;
    points: number;
    type: TypeOfQuestion;
    value?: AnswerType;
}

export type AnswerOptionModel = {
    optionID: string;
    text: string;
    isSelected: boolean;
    points: number;
}

export enum EducationLevel {
    CAP_BEP = "CAP_BEP",
    BAC = "BAC",
    BAC2 = "BAC2",
    BAC3 = "BAC3",
    BAC4 = "BAC4",
    BAC5 = "BAC5",
    BAC6 = "BAC6",
    PHD = "PHD",
    POSTDOC = "POSTDOC",
}

export const getDisplayName = (level: EducationLevel, intl: IntlShape): string => {
    switch (level) {
        case EducationLevel.CAP_BEP:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.CAP_BEP", defaultMessage: "Attestazione / Diploma Professionale" });
        case EducationLevel.BAC:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC", defaultMessage: "Diploma di Maturità" });
        case EducationLevel.BAC2:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC2", defaultMessage: "Laurea Breve" });
        case EducationLevel.BAC3:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC3", defaultMessage: "Laurea Triennale" });
        case EducationLevel.BAC4:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC4", defaultMessage: "Laurea Specialistica / a ciclo unico" });
        case EducationLevel.BAC5:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC5", defaultMessage: "Laurea Magistrale" });
        case EducationLevel.BAC6:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC6", defaultMessage: "Master 2° livello / Magistrale + specializzazione" });
        case EducationLevel.PHD:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.PHD", defaultMessage: "Dottorato" });
        case EducationLevel.POSTDOC:
            return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.POSTDOC", defaultMessage: "Ricerca post dottorato" });
        default:
            return "";
    }
};
