import React, { useState } from 'react';
import { AnswerOptionModel, AnswerType, FormQuestionModel, QuestionOptionModel } from '../../shared/models';
import { useIntl } from 'react-intl';

type DynamicFieldProps = {
    question: FormQuestionModel;
    onChange: (value: AnswerType) => void;
    disabled: boolean;
};

const DynamicField: React.FC<DynamicFieldProps> = ({ question, onChange, disabled }) => {
    const intl = useIntl();
    const [selectedOptions, setSelectedOptions] = useState<AnswerOptionModel[]>([]);

    const handleOptionChange = (option: QuestionOptionModel, isChecked: boolean) => {
        const updatedOptions = question.options?.map(opt => ({
            text: opt.text,
            optionID: opt.optionID,
            points: 0, //TODO: manage points
            isSelected: opt.optionID === option.optionID ? isChecked : selectedOptions.some(o => o.optionID === opt.optionID)
        })) || [];

        setSelectedOptions(updatedOptions.filter(o => o.isSelected));
        onChange(updatedOptions);
    };

    const handleSingleChoiceChange = (option: QuestionOptionModel, isChecked: boolean) => {
        const updatedOptions = question.options?.map(opt => ({
            text: opt.text,
            optionID: opt.optionID,
            points: 0, //TODO: manage points
            isSelected: opt.optionID === option.optionID ? isChecked : selectedOptions.some(o => o.optionID === opt.optionID)
        })) || [];

        setSelectedOptions(updatedOptions.filter(o => o.isSelected));
        onChange(updatedOptions);
    };

    switch (question.type) {
        case 'open':
            return (
                <textarea
                    onChange={(e) => onChange(e.target.value)}
                    className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-y"
                    required={question.isMandatory}
                    disabled={disabled}
                    rows={4}
                />
            );
        case 'boolean':
            return (
                <div className="radio-group flex flex-row items-center">
                    <label className='flex flex-row items-center mr-2'>
                        <input
                            className='mr-2'
                            type="radio"
                            name={`boolean-${question.questionID}`}
                            onChange={() => onChange(true)}
                            disabled={disabled}
                            required={question.isMandatory}
                        />
                        {intl.formatMessage({ id: "global.yes", defaultMessage: "Yes" })}
                    </label>
                    <label className='flex flex-row items-center'>
                        <input
                            className='mr-2'
                            type="radio"
                            name={`boolean-${question.questionID}`}
                            onChange={() => onChange(false)}
                            disabled={disabled}
                            required={question.isMandatory}
                        />
                        {intl.formatMessage({ id: "global.no", defaultMessage: "No" })}
                    </label>
                </div>
            );
        case 'multipleChoice':
            return (
                <div>
                    {question.options?.map(option => (
                        <div key={option.optionID} className="flex items-center mb-2">
                            <input
                                type="checkbox"
                                id={option.optionID}
                                checked={selectedOptions.some(o => o.optionID === option.optionID)}
                                onChange={(e) => handleOptionChange(option, e.target.checked)}
                                className="mr-2"
                                disabled={disabled}
                            />
                            <label htmlFor={option.optionID} className="text-gray-700">
                                {option.text}
                            </label>
                        </div>
                    ))}
                </div>
            );
        case 'singleChoice':
            return (
                <div>
                    {question.options?.map(option => (
                        <div key={option.optionID} className="flex items-center mb-2">
                            <input
                                type="radio"
                                id={option.optionID}
                                name={`single-${question.questionID}`}
                                checked={selectedOptions.some(o => o.optionID === option.optionID)}
                                onChange={(e) => handleSingleChoiceChange(option, e.target.checked)}
                                className="mr-2"
                                disabled={disabled}
                            />
                            <label htmlFor={option.optionID} className="text-gray-700">
                                {option.text}
                            </label>
                        </div>
                    ))}
                </div>
            );
        case 'date':
            return (
                <input
                    type="date"
                    onChange={(e) => onChange(e.target.value)}
                    className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required={question.isMandatory}
                    disabled={disabled}
                />
            );
        case 'dateTime':
            return (
                <input
                    type="datetime-local"
                    onChange={(e) => onChange(e.target.value)}
                    className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required={question.isMandatory}
                    disabled={disabled}
                />
            );
        default:
            return null;
    }
};

export default DynamicField;
