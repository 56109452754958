import { ApplicantFormData, JobFormAnswerModel, JobFormModel } from "../../shared/models";

export const acceptedResumeTypes = [
    'application/pdf',
    'application/vnd.oasis.opendocument.text',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/png',
    'image/jpeg'
];

export type CandidateRequestModel = {
    name: string;
    familyName: string;
    contact: string;
    // cvCoverName: string;
    // cvCoverInBase64: string;
    cvFileName: string;
    cvInBase64: string;
    city: string;
    state: string,
    province: string,
    address: string,
    fullAddress: string;
    postalCode: string;
    telephoneNumber: string;
    birthDate: string;
    studyLevel: string;
    jobTitle: string;
    yearsOfExperience: string;
    currentPosition: string;
    currentWorkingArea: string;
    formAnswers?: JobFormAnswerModel[];
};

export type JobApplicationRequestModel = {
    creationDateTime: string;
    jobRef: string;
    jobTitle: string;
    boardCode: string;
    candidate: CandidateRequestModel;
};


export interface Address {
    display_name: string;
    address: {
        house_number?: string;
        road?: string;
        street?: string;
        city?: string;
        town?: string;
        village?: string;
        postcode?: string;
        state?: string;
        country?: string;
    };
}
