// src/App.tsx
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import JobWidget from './pages/career-page/job-widget/job-widget';
import Layout from './Layout';
import JobDetailPage from './pages/career-page/job-detail/job-detail';
import UpdateResumePage from './pages/career-page/update-resume/update-resume';
import ApplicationThankYouPage from './pages/career-page/thank-you-application';
import NotFoundPage from './pages/career-page/not-found';
import Home from './pages/landing-page/home';
import UpdateResumeThankYouPage from './pages/career-page/thank-you-resume-update';

function App() {
  AOS.init();

  return (
    <Router>
      <div className="App text-gray font-plus-jakarta-sans">
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/home" element={<Layout><Home /></Layout>} />
          <Route path="/jobs/:customerId" element={<JobWidget />} />
          <Route path="/jobs/:customerId/:jobId" element={<JobDetailPage />} />
          <Route path="/update-resume/:customerId/:accessToken" element={<UpdateResumePage />} />
          <Route path="/thank-you-application-page" element={<ApplicationThankYouPage />} />
          <Route path="/thank-you-resume-update" element={<UpdateResumeThankYouPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
