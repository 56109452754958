import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import AddressAutocomplete from './address-autocomplete';
import { ApplicantFormData, EducationLevel, JobFormAnswerModel, JobFormModel, TypeOfQuestion, QuestionOptionModel, AnswerOptionModel, AnswerType } from '../../shared/models';
import { acceptedResumeTypes } from './career-page.models';
import DynamicField from './dynamic-field';

type ResumeFormProps = {
    onSubmit: (data: ApplicantFormData) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    privacyPolicyLinks: string[];
    color: string;
    radius: string;
    formTitle: string;
    buttonText: string;
    showOnlyResumeDrop?: boolean;
    questions?: JobFormModel[];
};

const ResumeForm: React.FC<ResumeFormProps> = ({
    onSubmit,
    loading,
    setLoading,
    privacyPolicyLinks,
    color,
    radius,
    formTitle,
    buttonText,
    showOnlyResumeDrop = false,
    questions = []
}) => {
    const intl = useIntl();

    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [resumeFile, setResumeFile] = useState<File | null>(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [resume, setResume] = useState<string | ArrayBuffer | null>(null);
    const [thankYouMessage, setThankYouMessage] = useState('');
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [privacyConsent, setPrivacyConsent] = useState(false);
    const [validationError, setValidationError] = useState<string | null>(null);

    const [fullAddress, setFullAddress] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const [birthDate, setBirthDate] = useState('');
    const [studyLevel, setStudyLevel] = useState<EducationLevel | "">("");
    const [currentPosition, setCurrentPosition] = useState('');
    const [yearsOfExperience, setYearsOfExperience] = useState('');
    const [currentWorkingArea, setCurrentWorkingArea] = useState('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [forms, setForms] = useState<JobFormModel[]>(questions);
    const [answerForms, setAnswerForms] = useState<JobFormAnswerModel[]>([]);

    useEffect(() => {
        // Initialize answerForms with the structure of JobFormAnswerModel
        setAnswerForms(forms.map(form => ({
            formID: form.formID,
            authorID: form.authorID,
            title: form.title,
            description: form.description,
            totalScore: 0,
            submissionDate: new Date().toISOString().slice(0, 16),
            answers: form.questions.map(question => ({
                questionID: question.questionID,
                type: question.type,
                questionText: question.questionText,
                options: question.options?.map(option => ({
                    option: option.text,
                    isSelected: false,
                    points: 0
                })) || [],
                isMandatory: question.isMandatory,
                points: 0,
                value: question.type === 'multipleChoice' || question.type === 'singleChoice' ? question.options : null
            }))
        })));
    }, [forms]);

    const handleAddressSelection = (address: {
        fullAddress: string;
        state: string;
        postalCode: string;
        city: string;
        province: string;
        address: string;
    }) => {
        setFullAddress(address.fullAddress);
        setState(address.state);
        setPostalCode(address.postalCode);
        setCity(address.city);
        setProvince(address.province);
        setAddress(address.address);
    };

    const getDisplayName = (level: EducationLevel): string => {
        switch (level) {
            case EducationLevel.CAP_BEP:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.CAP_BEP", defaultMessage: "Attestazione / Diploma Professionale" });
            case EducationLevel.BAC:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC", defaultMessage: "Diploma di Maturità" });
            case EducationLevel.BAC2:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC2", defaultMessage: "Laurea Breve" });
            case EducationLevel.BAC3:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC3", defaultMessage: "Laurea Triennale" });
            case EducationLevel.BAC4:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC4", defaultMessage: "Laurea Specialistica / a ciclo unico" });
            case EducationLevel.BAC5:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC5", defaultMessage: "Laurea Magistrale" });
            case EducationLevel.BAC6:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC6", defaultMessage: "Master 2° livello / Magistrale + specializzazione" });
            case EducationLevel.PHD:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.PHD", defaultMessage: "Dottorato" });
            case EducationLevel.POSTDOC:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.POSTDOC", defaultMessage: "Ricerca post dottorato" });
            default:
                return "";
        }
    };

    const handleStudyLevelSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as EducationLevel;
        setStudyLevel(value);
    };

    const handleFile = (file: File) => {
        if (acceptedResumeTypes.includes(file.type)) {
            if (file.size > 4.5 * 1024 * 1024) {
                alert(intl.formatMessage({ id: "careerPageApply.fileSize", defaultMessage: "File size must not exceed 4MB." }));
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1];
                setResume(base64String);
                setResumeFile(file);
            };
            reader.readAsDataURL(file);
        } else {
            alert(intl.formatMessage({ id: "careerPageApply.uploadFile", defaultMessage: "Please upload a valid file (PDF, ODT, DOC, DOCX, PNG, JPG)." }));
        }
    };

    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFile(file);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file) {
            handleFile(file);
        }
    };

    const handleDynamicFieldChange = (questionID: string, value: AnswerType) => {
        setAnswerForms(prevAnswerForms =>
            prevAnswerForms.map(form => ({
                ...form,
                answers: form.answers.map(answer => {
                    if (answer.questionID === questionID) {
                        const isEmptyArray = Array.isArray(value) && value.length === 0;

                        return {
                            ...answer,
                            value: isEmptyArray ? ((answer.type !== 'multipleChoice' && answer.type !== 'singleChoice') ? null : answer.value) : value,
                            points: answer.type === 'open' ? 0 : answer.points
                        };
                    }
                    return answer;
                })
            }))
        );
    };


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!resumeFile) {
            alert(intl.formatMessage({ id: "careerPageApply.validation.resume", defaultMessage: "Resume is required" }));
            return;
        }

        if (!privacyConsent) {
            alert(intl.formatMessage({ id: "careerPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" }));
            return;
        }

        setLoading(true);

        const formData: ApplicantFormData = {
            name,
            familyName,
            email,
            phone,
            resume,
            fullAddress,
            state,
            postalCode,
            city,
            province,
            address,
            birthDate,
            studyLevel,
            currentPosition,
            yearsOfExperience,
            currentWorkingArea,
            privacyConsent,
            formAnswers: answerForms.map(form => ({
                formID: form.formID,
                authorID: form.authorID,
                title: form.title,
                description: form.description,
                totalScore: form.totalScore,
                submissionDate: form.submissionDate,
                answers: form.answers.map(answer => ({
                    questionID: answer.questionID,
                    type: answer.type,
                    questionText: answer.questionText,
                    points: answer.points,
                    isMandatory: answer.isMandatory,
                    value: answer.value
                }))
            }))
        };


        onSubmit(formData);
    };

    return (
        <div className="flex justify-center items-center bg-gray-100">
            <div className="fill-available max-w-xxl bg-white p-8 rounded-lg shadow-md"
                style={{
                    borderRadius: radius
                }}>
                <h2 className="text-2xl font-bold mb-6">{formTitle}</h2>
                {thankYouMessage ? (
                    <div className="text-green-500">{thankYouMessage}</div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {showOnlyResumeDrop ? (
                            <>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.uploadResume", defaultMessage: "Upload Resume (PDF):" })}</label>
                                    <div
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleFileDrop}
                                        className="text-center fill-available py-8 border-dashed border-2 border-slate-300 rounded-lg flex justify-center items-center cursor-pointer"
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        {resumeFile ? (
                                            <span className='text-green-500'>{resumeFile.name}</span>
                                        ) : (
                                            <span>{intl.formatMessage({ id: "careerPageApply.dragAndDropCv", defaultMessage: "Drop your resume or click here" })}</span>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        className="hidden"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <div className="flex justify-start mb-4 items-center">
                                        <input
                                            type="checkbox"
                                            id="privacyConsent"
                                            className="mr-2"
                                            checked={privacyConsent}
                                            onChange={(e) => setPrivacyConsent(e.target.checked)}
                                        />
                                        <label htmlFor="privacyConsent" className="text-gray-700 text-left">
                                            {intl.formatMessage({
                                                id: "careerPageApply.privacyConsent",
                                                defaultMessage: "I agree to the privacy policy"
                                            })}
                                        </label>
                                    </div>
                                    <div className='flex justify-start flex-wrap'>
                                        {privacyPolicyLinks.map((link, index) => (
                                            <React.Fragment key={index}>
                                                <a
                                                    href={link.startsWith('http') ? link : `https://${link}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 underline"
                                                >
                                                    {`Policy ${index + 1}`}
                                                </a>
                                                {index < privacyPolicyLinks.length - 1 && <span> - </span>}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.firstName", defaultMessage: "First Name:" })} <span className="text-red-500">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        disabled={loading}
                                    />
                                    {validationError && !name.trim() && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.name", defaultMessage: "First Name is required" })}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.surname", defaultMessage: "Surname:" })} <span className="text-red-500">&nbsp;*</span></label>
                                    <input
                                        type="text"
                                        value={familyName}
                                        onChange={(e) => setFamilyName(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        disabled={loading}
                                    />
                                    {validationError && !familyName.trim() && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.surname", defaultMessage: "Surname is required" })}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.email", defaultMessage: "Email:" })} <span className="text-red-500">&nbsp;*</span></label>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        disabled={loading}
                                    />
                                    {validationError && !email.trim() && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.email", defaultMessage: "Email is required" })}
                                        </p>
                                    )}
                                    {validationError && email.trim() && !/^\S+@\S+\.\S+$/.test(email) && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.emailFormat", defaultMessage: "Invalid email format" })}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.phoneNumber", defaultMessage: "Phone:" })} <span className="text-red-500">&nbsp;*</span></label>
                                    <input
                                        type="tel"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        required
                                        disabled={loading}
                                    />
                                    {validationError && !phone.trim() && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.phone", defaultMessage: "Phone is required" })}
                                        </p>
                                    )}
                                    {validationError && phone.trim() && !/^\+?[\d\s-]{7,}$/.test(phone) && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.phoneFormat", defaultMessage: "Invalid phone format" })}
                                        </p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.uploadResume", defaultMessage: "Upload Resume (PDF):" })} <span className="text-red-500">&nbsp;*</span></label>
                                    <div
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={handleFileDrop}
                                        className="text-center fill-available py-8 border-dashed border-2 border-slate-300 rounded-lg flex justify-center items-center cursor-pointer"
                                        onClick={() => fileInputRef.current?.click()}
                                    >
                                        {resumeFile ? (
                                            <span className='text-green-500'>{resumeFile.name}</span>
                                        ) : (
                                            <span>{intl.formatMessage({ id: "careerPageApply.dragAndDropCv", defaultMessage: "Drop your resume or click here" })}</span>
                                        )}
                                    </div>
                                    <input
                                        type="file"
                                        accept="application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        className="hidden"
                                        disabled={loading}
                                    />
                                    {validationError && !resumeFile && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.resume", defaultMessage: "Resume is required" })}
                                        </p>
                                    )}
                                </div>

                                {/* Dynamic Form Section */}
                                {forms.map(form => (
                                    <React.Fragment key={form.formID}>
                                        {form.questions?.map(question => (
                                            <div key={question.questionID} className='text-left'>
                                                <label className="block text-left mb-2 text-gray-700">{question.questionText}
                                                    {question.isMandatory && <span className="text-red-500">&nbsp;*</span>}</label>

                                                <DynamicField
                                                    question={question}
                                                    onChange={(value: any) => handleDynamicFieldChange(question.questionID, value)}
                                                    disabled={loading}
                                                />
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}

                                <div className="flex justify-start align-middle items-center">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={showAdditionalInfo}
                                            onChange={() => setShowAdditionalInfo(!showAdditionalInfo)}
                                        />
                                        <span className="slider round"></span>
                                    </label>
                                    <span className="pl-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.addMoreInformation", defaultMessage: "Add more information" })}</span>
                                </div>
                                {/* Additional Information Section */}
                                {showAdditionalInfo && (
                                    <>
                                        <AddressAutocomplete onSelectAddress={handleAddressSelection} setLoading={setLoading}
                                            loading={loading} />

                                        <div>
                                            <label className="block text-left mb-2 text-gray-700">
                                                {intl.formatMessage({ id: "careerPageApply.birthDate", defaultMessage: "Birth Date:" })}
                                            </label>
                                            <input
                                                type="date"
                                                value={birthDate}
                                                onChange={(e) => setBirthDate(e.target.value)}
                                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                disabled={loading}
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-left mb-2 text-gray-700">
                                                {intl.formatMessage({ id: "careerPageApply.studyLevel", defaultMessage: "Study Level:" })}
                                            </label>
                                            <select
                                                value={studyLevel}
                                                onChange={handleStudyLevelSelectChange}
                                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none"
                                                disabled={loading}
                                            >
                                                {Object.values(EducationLevel).map((level) => (
                                                    <option key={level} value={level}>
                                                        {getDisplayName(level)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div>
                                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.currentPosition", defaultMessage: "Current Position:" })}</label>
                                            <input
                                                type="text"
                                                value={currentPosition}
                                                onChange={(e) => setCurrentPosition(e.target.value)}
                                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                disabled={loading}
                                            />
                                        </div>
                                        <div>
                                            <label className="block text-left mb-2 text-gray-700">
                                                {intl.formatMessage({ id: "careerPageApply.yearsOfExperience", defaultMessage: "Years Of Experience:" })}
                                            </label>
                                            <input
                                                type="number"
                                                value={yearsOfExperience}
                                                onChange={(e) => setYearsOfExperience(e.target.value)}
                                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                disabled={loading}
                                                min="0"
                                                step="1"
                                            />
                                        </div>

                                        <div>
                                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.currentWorkingArea", defaultMessage: "Current Working Area:" })}</label>
                                            <input
                                                type="text"
                                                value={currentWorkingArea}
                                                onChange={(e) => setCurrentWorkingArea(e.target.value)}
                                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                                disabled={loading}
                                            />
                                        </div>

                                    </>
                                )}

                                <div>
                                    <div className="flex justify-start items-center mb-4">
                                        <input
                                            type="checkbox"
                                            id="privacyConsent"
                                            className="mr-2"
                                            checked={privacyConsent}
                                            onChange={(e) => {
                                                setPrivacyConsent(e.target.checked);
                                                if (e.target.checked) {
                                                    setValidationError(null);
                                                }
                                            }}
                                        />
                                        <label htmlFor="privacyConsent" className="text-gray-700 text-left">
                                            {intl.formatMessage({
                                                id: "careerPageApply.privacyConsent",
                                                defaultMessage: "I agree to the privacy policy"
                                            })}
                                            <span className="text-red-500">&nbsp;*</span>
                                        </label>
                                    </div>

                                    <div className='flex justify-start flex-wrap'>
                                        {privacyPolicyLinks.map((link, index) => (
                                            <React.Fragment key={index}>
                                                <a
                                                    href={link.startsWith('http') ? link : `https://${link}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-blue-500 underline"
                                                >
                                                    {`Policy ${index + 1}`}
                                                </a>
                                                {index < privacyPolicyLinks.length - 1 && <span> - </span>}
                                            </React.Fragment>
                                        ))}
                                    </div>

                                    {validationError && !privacyConsent && (
                                        <p className="text-red-500 text-sm mt-1">
                                            {intl.formatMessage({ id: "careerPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" })}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                        <div className="text-center mt-6">
                            <button
                                type="submit"
                                className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 fill-available rounded-full transition duration-150 ease-in-out no-underline text-sm"
                                style={{
                                    backgroundColor: `#${color}`
                                }}
                                disabled={loading}
                            >
                                {loading ? intl.formatMessage({ id: "careerPageApply.submitting", defaultMessage: "Submitting..." }) : buttonText}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ResumeForm;
