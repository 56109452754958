import React, { useState, useRef, ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { Address } from './career-page.models';

type AddressAutocompleteProps = {
    onSelectAddress: (address: {
        fullAddress: string;
        state: string;
        postalCode: string;
        city: string;
        province: string;
        address: string;
    }) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({ onSelectAddress, loading, setLoading }) => {
    const [query, setQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Address[]>([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
    const intl = useIntl();

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null); // Ref to hold the debounce timeout

    const fetchSuggestions = async (input: string) => {
        setLoading(true);
        try {
            const response = await fetch(
                `https://nominatim.openstreetmap.org/search?q=${input}&format=json&addressdetails=1&limit=5`
            );
            const data: Address[] = await response.json();
            setSuggestions(data);
            setIsDropdownVisible(true);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setQuery(value);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current); // Clear the previous timeout
        }

        // Set a new debounce timeout
        debounceTimeout.current = setTimeout(() => {
            if (value.length > 2) {
                fetchSuggestions(value); // Fetch suggestions after user stops typing
            } else {
                setSuggestions([]);
                setIsDropdownVisible(false);
            }
        }, 500); // Delay the search by 500ms (can be adjusted)
    };

    const handleSuggestionClick = (address: Address) => {
        const houseNumber = address.address.house_number || '';
        const street = address.address.road || address.address.street || '';
        const city = address.address.city || address.address.town || address.address.village || '';
        const postalCode = address.address.postcode || '';
        const state = address.address.state || '';
        const province = address.address.state || '';
        const fullAddress = `${houseNumber ? houseNumber + ', ' : ''}${street}, ${city}, ${postalCode}, ${state}, ${address.address.country}`;

        onSelectAddress({
            fullAddress,
            state,
            postalCode,
            city,
            province,
            address: `${houseNumber} ${street}`,
        });

        setQuery(fullAddress);
        setSuggestions([]);
        setIsDropdownVisible(false);
    };

    return (
        <div className="relative">
            <label className="block text-left mb-2 text-gray-700">
                {intl.formatMessage({ id: "careerPageApply.searchAddress", defaultMessage: "Search Address:" })}
            </label>
            <input
                type="text"
                value={query}
                onChange={handleInputChange}
                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={intl.formatMessage({ id: "careerPageApply.searchAddress.placeholder", defaultMessage: "Es: via Roma 10, Roma" })}
            />
            {loading && (
                <div className="absolute z-10 mt-2 bg-white list-none py-6 fill-available rounded-lg shadow-lg max-h-60 overflow-y-auto border border-gray-300">
                    <p className="p-3 text-center">Loading...</p>
                </div>
            )}
            {isDropdownVisible && !loading && suggestions.length > 0 && (
                <ul className="absolute z-10 mt-2 bg-white list-none py-6 px-0 fill-available rounded-lg shadow-lg max-h-60 overflow-y-auto border border-gray-300">
                    {suggestions.map((address, index) => (
                        <li
                            key={index}
                            className="p-3 text-left hover:bg-blue-100 cursor-pointer border-b border-gray-200 last:border-none"
                            onClick={() => handleSuggestionClick(address)}
                        >
                            {address.display_name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AddressAutocomplete;
